import React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import Layout from '../components/layout';
export const _frontmatter = {
  "title": "Prior art"
};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = Layout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1>{`Prior art`}</h1>
    <p>{`Blocks didn't come out of a vacuum. There are a lot of projects that
have provided inspiration in one way or another. Below begins a list
(not intended to be comprehensive):`}</p>
    <ul>
      <li parentName="ul"><strong parentName="li">{`Compositor`}</strong>{`: The lab where a lot of these ideas were formed`}</li>
      <li parentName="ul"><strong parentName="li">{`Framer`}</strong>{`: Property controls for components and bringing in existing
components into a design tool`}</li>
      <li parentName="ul"><strong parentName="li">{`Storybook`}</strong>{`: Knobs, dials, and docs generation`}</li>
      <li parentName="ul"><strong parentName="li">{`Webflow`}</strong>{`: #nocode`}</li>
      <li parentName="ul"><strong parentName="li">{`Gutenberg`}</strong>{`: What's likely `}<em parentName="li">{`the`}</em>{` most popular block editor and ecosystem`}</li>
      <li parentName="ul"><strong parentName="li">{`Alva`}</strong>{`: React component-based WYSIWYG`}</li>
    </ul>
    <p>{`Is your project missing? Please `}<a parentName="p" {...{
        "href": "https://github.com/blocks/blocks"
      }}>{`open up a PR`}</a>{`.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      